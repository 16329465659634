
import React from 'react';
import '../../scss/main.scss';


function NotFound() {

  return (
    <section className='standard-page'>
      <h1>404</h1>
    </section>
  );

}

export default NotFound;