
import '../../scss/main.scss';


function Support() {

  return (
    <section className='standard-page'>
      <h1>Coming Soon</h1>
    </section>
  );

}

export default Support;