import React from 'react';
import '../../scss/main.scss';
import { DashboardSettingProps } from '../../types';

function BugsCategory(props: DashboardSettingProps) {

    return (
        <div className="dashboard-content" style={props.style}>

        </div>
    );
}

export default BugsCategory;